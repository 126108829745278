<template>
  <base-layout-two
    page-title="New Affiliate"
    page-default-back-link="/affiliates"
  >
    <div class="padding-x-8 padding-y-8">
      <v-form @submit="onSubmit">
        <base-card title="Affiliate Details">
          <ion-grid>
            <ion-row>
              <!-- Name -->
              <ion-col size="12" size-lg="6">
                <base-input label-text="Full Name *">
                  <v-field
                    name="name"
                    v-slot="{ field }"
                    v-model="affiliate.name"
                    :rules="required"
                  >
                    <ion-input
                      name="name"
                      v-bind="field"
                      placeholder="Jane Doe"
                      type="text"
                      autocapitalize="words"
                      autocorrect
                    >
                    </ion-input>
                  </v-field>
                </base-input>
                <v-error-message name="name" class="error-message">
                </v-error-message>
              </ion-col>

              <!-- Type -->
              <ion-col size="12" size-lg="6">
                <base-input label-text="Type *">
                  <v-field
                    name="type"
                    v-slot="{ field }"
                    v-model="affiliate.type"
                    :rules="required"
                  >
                    <select v-bind="field" class="regular-select">
                      <option value="business">Business</option>
                      <option value="influencer">Influencer</option>
                    </select>
                  </v-field>
                </base-input>
                <v-error-message name="type" class="error-message">
                </v-error-message>
              </ion-col>

              <!-- Code -->
              <ion-col size="12" size-lg="6">
                <base-input label-text="Code *">
                  <v-field
                    name="code"
                    v-slot="{ field }"
                    v-model="affiliate.code"
                    :rules="required"
                  >
                    <ion-input
                      name="code"
                      v-bind="field"
                      placeholder="jane"
                      type="text"
                    >
                    </ion-input>
                  </v-field>
                </base-input>
                <v-error-message name="name" class="error-message">
                </v-error-message>
              </ion-col>

              <!-- URL -->
              <ion-col size="12" size-lg="6">
                <base-input label-text="URL">
                  <v-field
                    name="url"
                    v-slot="{ field }"
                    v-model="affiliate.url"
                  >
                    <ion-input
                      name="url"
                      v-bind="field"
                      placeholder="https://go.boramcare.com/code"
                      type="text"
                    >
                    </ion-input>
                  </v-field>
                </base-input>
                <v-error-message name="url" class="error-message">
                </v-error-message>
              </ion-col>

              <!-- Email -->
              <ion-col size="12" size-lg="6">
                <base-input label-text="Email">
                  <v-field
                    name="email"
                    v-slot="{ field }"
                    v-model="affiliate.email"
                  >
                    <ion-input
                      name="email"
                      v-bind="field"
                      placeholder="jane@doe.com"
                      type="email"
                    >
                    </ion-input>
                  </v-field>
                </base-input>
                <v-error-message name="email" class="error-message">
                </v-error-message>
              </ion-col>

              <!-- Number -->
              <ion-col size="12" size-lg="6">
                <base-input label-text="Phone Number">
                  <v-field
                    name="number"
                    v-slot="{ field }"
                    v-model="affiliate.number"
                  >
                    <ion-input
                      name="number"
                      v-bind="field"
                      placeholder="5557894564"
                      type="tel"
                    >
                    </ion-input>
                  </v-field>
                </base-input>
                <v-error-message name="number" class="error-message">
                </v-error-message>
              </ion-col>
            </ion-row>
          </ion-grid>

          <ion-button
            type="submit"
            expand="block"
            class="margin-x-8 margin-y-16"
          >
            Save
          </ion-button>
        </base-card>
      </v-form>
    </div>
  </base-layout-two>
</template>

<script>
import { IonGrid, IonRow, IonCol, IonInput, IonButton } from "@ionic/vue";
import { Field, Form, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { mapActions } from "vuex";

export default {
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonInput,
    IonButton,
    VForm: Form,
    VField: Field,
    VErrorMessage: ErrorMessage,
  },

  data() {
    return {
      affiliate: {},
      required: yup.string().required(),
    };
  },

  methods: {
    ...mapActions(["loadToast", "setShowLoading"]),

    async onSubmit() {
      const config = {
        method: "post",
        url: `${process.env.VUE_APP_API}/affiliates`,
        data: this.affiliate,
      };

      // Show a network loading indicator
      this.setShowLoading(true);

      await this.axios(config)
        .then((response) => {
          // Error Message
          if (response.data.message) {
            this.setShowLoading(false);
            this.loadToast({ message: response.data.message, color: "danger" });
          }
          // Success
          else {
            this.affiliate = response.data.affiliate;

            // Turn off the network loading indicator and prompt user with a toast
            this.setShowLoading(false);
            this.loadToast({
              message: "Affiliate Created",
              color: "secondary",
            });

            this.$router.replace(`/affiliates/${this.affiliate.id}`);
          }
        })
        .catch((error) => {
          console.log(error);

          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);
          this.loadToast({ message: "Something went wrong", color: "danger" });
        });
    },
  },
};
</script>